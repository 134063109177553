import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'MENUITEMS.MENU.TEXT',
    //     isTitle: true
    // },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'lock-icon',
        // badge: {
        //     variant: 'success',
        //     text: 'MENUITEMS.DASHBOARDS.BADGE',
        // },
        link: '/dashboard'
    }, {
        id: 3,
        label: 'MENUITEMS.SCAN_SUMMARY.TEXT',
        icon: 'scan-icon',
        // badge: {
        //     variant: 'success',
        //     text: 'MENUITEMS.DASHBOARDS.BADGE',
        // },

        link: '/scans'
    }, {
    id: 4,
    label: 'MENUITEMS.WEB_SECURITY.TEXT',
    icon: 'globe-icon',
    // badge: {
    //     variant: 'success',
    //     text: 'MENUITEMS.DASHBOARDS.BADGE',
    // },
    link: '/web-security'

  },{
    id: 5,
    label: 'MENUITEMS.CLOUD_SECURITY.TEXT',
    icon: 'vector-icon',
    // badge: {
    //     variant: 'success',
    //     text: 'MENUITEMS.DASHBOARDS.BADGE',
    // },
    link: '/cloud-security'

  },{
    id: 6,
    label: 'MENUITEMS.COMPLIANCE_HEALTH.TEXT',
    icon: 'clipboard-icon',
    // badge: {
    //     variant: 'warning',
    //     text: 'MENUITEMS.COMPLIANCE_HEALTH.BADGE',
    // },
    link: '/compliance'

  },

    // {
    //     id: 16,
    //     isLayout: true
    // },
    // {
    //     id: 17,
    //     label: 'MENUITEMS.PAGES.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 18,
    //     label: 'MENUITEMS.AUTHENTICATION.TEXT',
    //     icon: 'ri-account-circle-line',
    //     subItems: [
    //         {
    //             id: 19,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
    //             link: '/pages/login-1',
    //             parentId: 18
    //         },
    //         {
    //             id: 20,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
    //             link: '/pages/register-1',
    //             parentId: 18
    //         },
    //         {
    //             id: 21,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
    //             link: '/pages/recoverpwd-1',
    //             parentId: 18
    //         },
    //         {
    //             id: 22,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
    //             link: '/pages/lock-screen-1',
    //             parentId: 18
    //         }
    //     ]
    // },
    // {
    //     id: 23,
    //     label: 'MENUITEMS.UTILITY.TEXT',
    //     icon: 'ri-profile-line',
    //     subItems: [
    //         {
    //             id: 24,
    //             label: 'MENUITEMS.UTILITY.LIST.STARTER',
    //             link: '/pages/starter',
    //             parentId: 23
    //         },
    //         {
    //             id: 25,
    //             label: 'MENUITEMS.UTILITY.LIST.MAINTENANCE',
    //             link: '/pages/maintenance',
    //             parentId: 23
    //         },
    //         {
    //             id: 26,
    //             label: 'MENUITEMS.UTILITY.LIST.COOMINGSOON',
    //             link: '/pages/coming-soon',
    //             parentId: 23
    //         },
    //         {
    //             id: 27,
    //             label: 'MENUITEMS.UTILITY.LIST.TIMELINE',
    //             link: '/pages/timeline',
    //             parentId: 23
    //         },
    //         {
    //             id: 28,
    //             label: 'MENUITEMS.UTILITY.LIST.FAQS',
    //             link: '/pages/faqs',
    //             parentId: 23
    //         },
    //         {
    //             id: 29,
    //             label: 'MENUITEMS.UTILITY.LIST.PRICING',
    //             link: '/pages/pricing',
    //             parentId: 23
    //         },
    //         {
    //             id: 30,
    //             label: 'MENUITEMS.UTILITY.LIST.ERROR404',
    //             link: '/pages/404',
    //             parentId: 23
    //         },
    //         {
    //             id: 31,
    //             label: 'MENUITEMS.UTILITY.LIST.ERROR500',
    //             link: '/pages/500',
    //             parentId: 23
    //         },
    //     ]
    // },

];
