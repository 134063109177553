import {Component, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

import {AuthenticationService} from '../../../core/services/auth.service';
import {LanguageService} from '../../../core/services/language.service';
import {environment} from '../../../../environments/environment';
import {LOCAL_STORAGE, WebStorageService} from "ngx-webstorage-service";
import {ScansService} from "../../../core/services/scans.service";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {CloudSecurityDialogComponent} from "../../../pages/cloud-security/cloud-security-dialog/cloud-security-dialog.component";
import {LaunchScanDialogComponent} from "../../../pages/scans/launch-scan-dialog/launch-scan-dialog.component";
import {UserService} from "../../../core/services/user.service";
import {AlertService} from "../../../shared/alert/services/alert.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

    element: any;
    configData: any;
    cookieValue;
    flagvalue;
    countryName;
    valueset: string;
    user: any;
    data= <any> {};

    listLang = [
        {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
        {text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es'},
        {text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de'},
        {text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it'},
        {text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru'},
    ];

    // tslint:disable-next-line: max-line-length
    constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
                public languageService: LanguageService, public cookiesService: CookieService,
                @Inject(LOCAL_STORAGE) private storage: WebStorageService, private scansService: ScansService, private dialog: MatDialog,
                private userService: UserService, private alertService: AlertService, private dialogRef: MatDialog) {
        this.user = this.storage.get('loggedInUser');
    }

    @Output() mobileMenuButtonClicked = new EventEmitter();
    @Output() settingsButtonClicked = new EventEmitter();

    ngOnInit(): void {
        this.element = document.documentElement;
        this.configData = {
            suppressScrollX: true,
            wheelSpeed: 0.3
        };

        this.cookieValue = this.cookiesService.get('lang');
        const val = this.listLang.filter(x => x.lang === this.cookieValue);
        this.countryName = val.map(element => element.text);
        if (val.length === 0) {
            if (this.flagvalue === undefined) {
                this.valueset = 'assets/images/flags/us.jpg';
            }
        } else {
            this.flagvalue = val.map(element => element.flag);
        }
      this.getscanLimit();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Toggles the right sidebar
     */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
     * Fullscreen method
     */
    fullscreen() {
        document.body.classList.toggle('fullscreen-enable');
        if (
            !document.fullscreenElement && !this.element.mozFullScreenElement &&
            !this.element.webkitFullscreenElement) {
            if (this.element.requestFullscreen) {
                this.element.requestFullscreen();
            } else if (this.element.mozRequestFullScreen) {
                /* Firefox */
                this.element.mozRequestFullScreen();
            } else if (this.element.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.element.webkitRequestFullscreen();
            } else if (this.element.msRequestFullscreen) {
                /* IE/Edge */
                this.element.msRequestFullscreen();
            }
        } else {
            if (this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }

    /**
     * Translate language
     */
    setLanguage(text: string, lang: string, flag: string) {
        this.countryName = text;
        this.flagvalue = flag;
        this.cookieValue = lang;
        this.languageService.setLanguage(lang);
    }


    /**
     * Logout the user
     */
    logout() {
        this.userService.logoutUser().subscribe((res: any) => {
            sessionStorage.clear();
            localStorage.clear();
            this.dialogRef.closeAll();
            // window.location.href = '/account/login';
          // this.dialogRef.afterAllClosed.subscribe(() => this.router.navigate(['/account/login']));

            this.router.navigate(['/account/login']);

        }, error1 => {
            this.alertService.error(error1 ? error1['message'] : error1)

        })
    }
    // onNewScanClick() {
    //     this.dialog.open(LaunchScanDialogComponent, {
    //         width: '450px',
    //         autoFocus: false,
    //         restoreFocus: false
    //
    //     });
    // }

    public getscanLimit() {
      this.userService.getScanUsageLimit().subscribe((data) => {
        // console.log(data);
        this.data = data;
        // console.log(this.data);
      }, error1 => {
        this.alertService.error(error1 ? error1['message'] : error1)

      })
    }

}
