import {Inject, Injectable} from '@angular/core';
import {CommonService} from "./common.service";
import {HttpClient} from "@angular/common/http";
import {LOCAL_STORAGE, WebStorageService} from "ngx-webstorage-service";
import {environment} from "../../../environments/environment";
import {apiConfig} from "../../api-url.config";
import {catchError, map} from "rxjs/operators";
import {throwError} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ScansService extends CommonService {

    API_END_POINT: string;

    constructor(private httpClient: HttpClient, @Inject(LOCAL_STORAGE) private storage: WebStorageService,) {
        super();
        this.API_END_POINT = environment.API_URL;
    }

    getUserScans(userId: any, search: any) {
        let params = this.generateHttpParams(search);
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scan.getUserTeamScans}`, {params})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );

    }

    reinitiateScan(scanId: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.scan.getUserScans}/${scanId}${apiConfig.scan.reinitiate}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getScanSummaryData(scanId: string) {
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scan.getUserScans}/${scanId}${apiConfig.scan.summary}`)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }


    getScanDetailsData(scanId: string, scanOptionCode: string, search: { page: number; size: number }) {
        let params = this.generateHttpParams(search);
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scan.getUserScans}/${scanId}/${scanOptionCode}`, {params})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }
    launchScan(payload: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.scan.launchScan}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    validateDomain(domainUrl: any) {
        let params = this.generateHttpParams(domainUrl);
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scan.validateDomain}`, {params})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

}
