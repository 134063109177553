<div class="card" [ngClass]="bgClass=='red'?' content-card-red':' content-card'" (mouseover)="hovered=true"
     (mouseleave)="hovered=false">
    <div class="card-body d-flex justify-content-between align-items-center m-2">
        <div class="col-2 p-0">
            <div class=" d-flex justify-content-center align-items-center"
                 [ngClass]="bgClass=='red'?'card-icon-red':'card-icon'">
                <i [ngClass]="hovered?'box-icon-filled':'box-icon'" *ngIf="icon=='box'" ></i>
                <i [ngClass]="hovered?'users-icon-filled':'users-icon'" *ngIf="icon=='users'"></i>
                <i [ngClass]="hovered?'anchor-icon-filled':'anchor-icon'" *ngIf="icon=='anchor'"></i>
                <i [ngClass]="hovered?'database-icon-filled':'database-icon'" *ngIf="icon=='database'"></i>
            </div>
        </div>
        <div class="col-9 d-flex justify-content-start">
            <div class="text-center  ml-n2">
                <h3 class="text-left count-text text-left">{{count}}</h3>
              <span class=" text-nowrap text-left"> {{title}}</span>
            </div>

        </div>
    </div>
</div>
