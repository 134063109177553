<div class="dialog-top-border-bg"></div>
<div class="dialog-container-padding">
    <div class="row d-flex justify-content-between pl-2 pr-2">
        <div class="text-left">
            <div class="mat-dialog-title d-flex align-items-end align-text-bottom mb-1">Launch Scan
          <!--<div id="openMain"><small class="pl-1 text-primary pointer"  (click)="openNav1()">Info</small></div>-->
            </div>
            <!--<div class="text-left"><p class="text-warning">Only 3 scans allowed in trial version</p></div>-->
        </div>
        <button class="btn btn-light-grey-bg rounded-circle d-flex justify-content-center align-items-center"
                matDialogClose="" style="width: 34.57px;
height: 34.57px; "><i class="close-icon d-flex justify-content-center align-items-center"></i></button>
    </div>
    <form [formGroup]="dataForm" class="mb-2 launch-scroll">
        <!--                <div class="col-md-4 mb-3">-->
        <!--                    <label for="validationCustomUsername">Username</label>-->
        <!--                    <div class="input-group">-->
        <!--                        <div class="input-group-prepend">-->
        <!--                            <span class="input-group-text" id="inputGroupPrepend">@</span>-->
        <!--                        </div>-->
        <!--                        <input type="text" class="form-control" id="validationCustomUsername" placeholder="Username" aria-describedby="inputGroupPrepend" required>-->
        <!--                        <div class="invalid-feedback">-->
        <!--                            Please choose a username.-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--        <div class="input-group">-->
        <!--            <div class="input-group-prepend">-->
        <!--                <div class="input-group-text dropdown" id="inputGroupPrepend">-->
        <!--                    <div class=" dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"-->
        <!--                         aria-haspopup="true" aria-expanded="false">-->
        <!--                        {{urlPrefix}}-->
        <!--                    </div>-->
        <!--                    <div class="dropdown-menu ml-n3" aria-labelledby="dropdownMenuButton">-->
        <!--                        <a class="dropdown-item text-white" (click)="urlPrefix='http://'">http://</a>-->
        <!--                        <a class="dropdown-item text-white" (click)="urlPrefix='https://'">https://</a>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--            <input type="url" class="form-control form-control-lg text-white"-->
        <!--                   formControlName="domainUrl"-->
        <!--                   aria-describedby="inputGroupPrepend"-->
        <!--                   placeholder="Enter Domain url" [ngClass]="{'is-invalid':isInvalidDomain}" required-->
        <!--                   (focusout)="onDomainUrlChange($event.target.value)">-->
        <!--        </div>-->
      <div class="form-group">
        <label class="mb-2 input-label ">Domain Url<span class="text-danger pl-1">*</span></label>
        <div class="input-group" id="domainUrl">
            <div class="input-group-prepend  col-3 p-0" ngbDropdown style="width: 101px">


                <div class="input-group-text dropdown domain-url-drop-down-border" ngbDropdownToggle
                     id="inputGroupPrepend" style="width: 100%">
                    <div class=" dropdown-toggle d-flex align-items-center" id="dropdownMenuButton"
                         data-toggle="dropdown"
                         aria-haspopup="true" aria-expanded="false">
                        {{urlPrefix}} <i class="mdi mdi-chevron-down pl-2 font-size-18"></i>
                    </div>
                    <div class="dropdown-menu ml-n3"
                         aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
                        <a class="dropdown-item "
                           (click)="urlPrefix='http://'">http://</a>
                        <a class="dropdown-item "
                           (click)="urlPrefix='https://'">https://</a>
                    </div>
                </div>
            </div>

            <input type="text" class=" form-control domain-url-border col-9 p-0 pl-3"
                   placeholder="Enter your Domain Url"
                   formControlName="domainUrl"
                   (focusout)="onDomainUrlChange($event.target.value)"
                   [ngClass]="{ 'is-invalid': (isSubmitted|| dataForm.get('domainUrl').touched) && dataForm.get('domainUrl').errors }">
        </div>
        <small id="emailHelp" class="error-text"
               *ngIf="(isSubmitted|| dataForm.controls.domainUrl.touched) && dataForm.controls.domainUrl.errors?.required">Domain
            Url
            is
            required</small>
        <small id="emailHelp2" class="error-text"
               *ngIf="(isSubmitted|| dataForm.controls.domainUrl.touched) && dataForm.controls.domainUrl.errors?.pattern">Invalid
            Domain Url</small>
      </div>

        <div class="mt-3">
            <span class="sub-heading">Customize Scan Options</span>
            <!--<div class="text-warning">Disabled options will not be scanned in this session</div>-->
            <div class="mt-3">
                <div class="form-check pl-0" *ngFor="let item of scanOptions;let i=index">
                    <div class="custom-control custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input" [id]="i"
                               [checked]="item.checked"
                               [disabled]="item.disabled" (change)="item.checked=$event.target.checked">
                        <label class="custom-control-label checkbox-label" [for]="i">{{item.title}}</label>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center mt-3 mb-3">
                <div class="col-12 hr"></div>
            </div>

          <!-- dialog sidebar -->
          <div class="row  pb-2 d-flex justify-content-center align-items-center" style="font-size:13px; font-weight:bold; text-align:center;">
            <div class="col-10 col-md-10">**<a href="javascript:void(0)" (click)="onContactSupportClick()"> Request for manual authenticated Asset Discovery scan</a> with ZERO FALSE POSITIVES.</div>
            <div id="openMain" class="col-1 col-md-1 d-flex align-items-center pointer" (click)=" openNavLaunchPopup()"> <i class="info-blue-color" ></i></div>
          </div>
          <!-- dialog sidebar End -->


            <div class="form-check pl-0 ">
                <div class="custom-control custom-checkbox mb-2">
                    <input type="checkbox" class="custom-control-input" id="ownerCheckBox"
                           formControlName="isAgreedOwnerOfTheDomain">
                    <label class="custom-control-label checkbox-label" for="ownerCheckBox">I am authorized to scan this domain.<span class="text-danger pl-1">*</span></label>
                </div>
            </div>
            <span class="error-text">{{errorMsg}}</span>
            <div class="d-flex justify-content-center pt-2 mt-3">
                <button class="btn btn-dark-outline mr-2" type="button" matDialogClose="">Cancel
                </button>
                <button class="btn btn-blue-secondary" type="button"
                        [disabled]="disabledSubmit || !this.dataForm.get('isAgreedOwnerOfTheDomain').value || dataForm.invalid || isLoading"
                        (click)="onLaunchScan(false)">{{submitBtntext}} <i
                        *ngIf="isLoading"
                        class="fa fa-spinner fa-spin"></i>
                </button>
            </div>
        </div>

    </form>

</div>
