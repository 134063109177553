import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {LOCAL_STORAGE, WebStorageService} from "ngx-webstorage-service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let clonedRequest: any = {};
        if (this.storage.get('loggedInUser')) {
            const userDetails = this.storage.get('loggedInUser');
            clonedRequest = request.clone({
                headers: request.headers.set('Authorization', `${userDetails.accessToken}`)
            });
        } else {
            clonedRequest = request.clone({
                headers: request.headers
            });
        }
        // send the newly created request
        return next.handle(clonedRequest);
    }
}
