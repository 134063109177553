<app-banner></app-banner>

<div class="container-fluid">
    <!--  <app-pagetitle title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>-->
    <h4 class="text-left"><span class="heading-1">Dashboard </span><span class="heading-2"></span></h4>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3" style="min-width: 300px !important;">
            <app-dashboard-card count="{{this.subDomainsCount}}" title="Web Assets"
                                icon="box"></app-dashboard-card>
        </div>
        <div class="col-12 col-sm-6 col-md-3" style="min-width: 300px !important;">
            <app-dashboard-card count="{{this.employeeAssets}}" title="Employee Assets"
                                icon="users"></app-dashboard-card>
        </div>
        <div class="col-12 col-sm-6 col-md-3" style="min-width: 300px !important;">
            <app-dashboard-card count="{{this.phishingDomainsCount}}" title="Potential Phishing Domains" icon="anchor"
                                bgClass="red"></app-dashboard-card>
        </div>
        <div class="col-12 col-sm-6 col-md-3" style="min-width: 300px !important;">
            <app-dashboard-card count="{{this.sourceCodeLeakageCount}}" title="Breached Data" icon="database"
                                bgClass="red"></app-dashboard-card>
        </div>
    </div>
    <div class="row equal-cols">
        <div class="col-12  col-md-5">
            <div class="card main-card">
                <div class="card-body">
                  <div class="d-flex justify-content-center">
                    <div class="p-2"><h4 class="  chart-heading">Risk By Category</h4></div>
                    <div class="ml-auto p-2 pointer d-none d-sm-none d-md-block" matTooltip="This plot displays percentage public exposure for each category"
                         matTooltipPosition="above">
                      <i class="info-icon" ></i>
                    </div>
                    <div  class="ml-auto p-2 pointer d-block d-sm-block d-md-none d-lg-none d-xl-none"
                         matTooltip="This plot displays percentage public exposure for each category"
                         matTooltipPosition="above" #tooltip3="matTooltip" (click)="tooltip3.toggle()">
                      <i class="info-icon" ></i>
                    </div>
                  </div>

                    <div class="row" id="apex-chart">
                        <div class="col-12 col-sm-6 col-md-7 d-flex align-items-center justify-content-center pr-0">
                            <apx-chart dir="ltr" class="apex-charts" *ngIf="riskByCategoryAnalysis"
                                       [series]="riskByCategoryAnalysis.series"
                                       [chart]="riskByCategoryAnalysis.chart"
                                       [legend]="riskByCategoryAnalysis.legend"
                                       [colors]="riskByCategoryAnalysis.colors"
                                       [labels]="riskByCategoryAnalysis.labels"
                                       [dataLabels]="riskByCategoryAnalysis.dataLabels"
                                       [plotOptions]="riskByCategoryAnalysis.plotOptions">
                            </apx-chart>

                        </div>
                        <div class=" col-sm-6 col-md-5 text-left pl-0">
                            <div class="col-12  grey-card " style="min-width: fit-content;">
                                <div class=" row pt-2 ">
                                    <div class="col-1 col-md-1">
                                        <i class="mdi mdi-circle blue font-size-10 mr-1"></i>
                                    </div>
                                    <div class="col-10 col-md-10">
                                        <p class="mb-0 text-truncate chart-assets-item "> Web Assets
                                        </p>
                                        <h5 *ngIf="riskByCategoryAnalysis"
                                            class="chart-assets-item-value">{{riskByCategoryAnalysis.series[0]}}
                                            %</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 grey-card" style="min-width: fit-content;">
                                <div class=" row pt-2">
                                    <div class="col-1 col-md-1">
                                        <i class="mdi mdi-circle blue-light  font-size-10 mr-1"></i>
                                    </div>
                                    <div class="col-10 col-md-10">
                                        <p class="mb-0 text-truncate chart-assets-item"> Employee Assets
                                        </p>
                                        <h5 *ngIf="riskByCategoryAnalysis"
                                            class="chart-assets-item-value">{{riskByCategoryAnalysis.series[1]}}
                                            %</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 grey-card">
                                <div class=" row pt-2">
                                    <div class="col-1 col-md-1">
                                        <i class="mdi mdi-circle green font-size-10 mr-1"></i>
                                    </div>
                                    <div class="col-10 col-md-10">
                                        <p class=" text-truncate mb-0 chart-assets-item text-wrap">Potential Phishing Domains
                                        </p>
                                        <h5 *ngIf="riskByCategoryAnalysis"
                                            class="chart-assets-item-value">{{riskByCategoryAnalysis.series[2]}}
                                            %</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 grey-card">
                                <div class=" row pt-2">
                                    <div class="col-1 col-md-1">
                                        <i class="mdi mdi-circle green-light font-size-10 mr-1"></i>
                                    </div>
                                    <div class="col-10 col-md-10">
                                        <p class="mb-0 text-truncate chart-assets-item"> Breached Data
                                        </p>
                                        <h5 *ngIf="riskByCategoryAnalysis"
                                            class="chart-assets-item-value">{{riskByCategoryAnalysis.series[3]}}
                                            %</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-7 ">
            <div class="card main-card">
                <div class="card-body">
                    <!--<h4 class=" mb-4  ml-3 chart-heading">Comparative Security</h4>-->
                  <div class="d-flex justify-content-center">
                    <div class="p-2"><h4 class="  chart-heading">Comparative Security</h4></div>
                    <div class="ml-auto p-2 pointer d-none d-sm-none d-md-block "
                         matTooltip="Here you can see the last 50 scans data" matTooltipPosition="above">
                      <i class="info-icon" ></i>
                    </div>
                    <div class="ml-auto p-2 pointer d-block d-sm-block d-md-none d-lg-none d-xl-none"
                         matTooltip="Here you can see the last 50 scans data"
                         matTooltipPosition="above" #tooltip2="matTooltip" (click)="tooltip2.toggle()">
                      <i class="info-icon" ></i>
                    </div>
                  </div>
                    <div class="row  d-flex justify-content-around pt-2">
                        <div class="row col-12 col-md-3">
                            <p class="mb-0 text-truncate chart-assets-item  "><i
                                    class="mdi mdi-circle blue font-size-10 mr-1"></i> Web Assets
                            </p>
                        </div>
                        <div class="row col-12 col-md-3">
                            <p class="mb-0 text-truncate chart-assets-item"><i
                                    class="mdi mdi-circle blue-light font-size-10 mr-1"></i> Employee Assets
                            </p>
                        </div>
                        <div class="row col-12 col-md-3 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                            <p class="mb-0 text-truncate chart-assets-item text-wrap"><i
                              class="mdi mdi-circle green font-size-10 mr-2 "></i>Potential Phishing <span class="phishing">Domains</span>
                            </p>
                        </div>
                      <div class="row col-12 col-md-3 d-block s-sm-block d-md-none d-lg-none d-xl-none">
                            <p class="mb-0 text-truncate chart-assets-item text-wrap"><i
                              class="mdi mdi-circle green font-size-10 mr-2 "></i>Potential Phishing Domains
                            </p>
                        </div>
                        <div class="row col-12 col-md-3">
                            <p class="mb-0 text-truncate chart-assets-item"><i
                                    class="mdi mdi-circle green-light font-size-10 mr-1"></i> Breached Data
                            </p>
                        </div>
                    </div>
                    <!--<h4 class="text-center pt-2 " *ngIf="!comparativeSecurityAnalysis">No Data Found</h4>-->
                    <div class="row">
                      <div class="col-md-12 d-flex align-items-center justify-content-center pr-0 pl-0 ml-0 mr-0 overflow-auto">
                        <apx-chart dir="ltr" class="apex-charts overflow-auto " *ngIf="comparativeSecurityAnalysis"
                                   [series]="comparativeSecurityAnalysis.series"
                                   [chart]="comparativeSecurityAnalysis.chart"
                                   [legend]="comparativeSecurityAnalysis.legend"
                                   [xaxis]="comparativeSecurityAnalysis.xaxis"
                                   [colors]="comparativeSecurityAnalysis.colors"
                                   [labels]="comparativeSecurityAnalysis.labels"
                                   [dataLabels]="comparativeSecurityAnalysis.dataLabels"
                                   [plotOptions]="comparativeSecurityAnalysis.plotOptions"
                                   [responsive]="comparativeSecurityAnalysis.responsive"
                                   [yaxis]="comparativeSecurityAnalysis.yaxis"
                                   [fill]="comparativeSecurityAnalysis.fill">
                        </apx-chart>
                      </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <div class="row equal-cols">
        <div class="col-md-7 ">
            <div class="card main-card">
                <div class="card-body">
                    <!--<h4 class=" mb-4  ml-3"><span class="chart-heading"> Assets Timeline</span></h4>-->
                  <div class="d-flex justify-content-center">
                    <div class="p-2"><h4 class="  chart-heading">Assets Timeline</h4></div>
                    <div class="ml-auto p-2 pointer d-none d-sm-none d-md-block"
                         matTooltip="Click on the chart to check respective scan results."
                         matTooltipPosition="above">
                      <i class="info-icon" ></i>
                    </div>
                    <div class="ml-auto p-2 pointer d-block d-sm-block d-md-none d-lg-none d-xl-none "
                         matTooltip="Click on the chart to check respective scan results."
                         matTooltipPosition="above" #tooltip1="matTooltip" (click)="tooltip1.toggle()">
                      <i class="info-icon" ></i>
                    </div>
                  </div>
                    <div class="row">
                        <div class="col-sm-8 col-md-8  d-flex align-items-center justify-content-center new ">
                            <apx-chart dir="ltr" class="apex-charts col-12 " *ngIf="assetsTimelineChartData"
                                       [series]="assetsTimelineChartData.series"
                                       [chart]="assetsTimelineChartData.chart"
                                       [xaxis]="assetsTimelineChartData.xaxis"
                                       [legend]="assetsTimelineChartData.legend"
                                       [colors]="assetsTimelineChartData.colors"
                                       [labels]="assetsTimelineChartData.labels"
                                       [dataLabels]="assetsTimelineChartData.dataLabels"
                                       [plotOptions]="assetsTimelineChartData.plotOptions"
                                       [responsive]="assetsTimelineChartData.responsive"
                                       [yaxis]="assetsTimelineChartData.yaxis">
                            </apx-chart>
                        </div>
                        <div class="col-sm-4 col-md-4 text-left d-flex align-items-center">
                            <div class=" mt-4">
                                <div class="col-12 ">
                                    <div class=" row d-flex align-items-center ">
                                        <div class="col-1 col-md-1">
                                            <i class="mdi mdi-circle blue font-size-10 mr-1"></i>
                                        </div>
                                        <div class="col-10 col-md-10">
                                            <p class="mb-0 text-truncate chart-assets-item"> Web Assets
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 ">
                                    <div class=" row mt-4  d-flex align-items-center">
                                        <div class="col-1 col-md-1">
                                            <i class="mdi mdi-circle blue-light font-size-10 mr-1"></i>
                                        </div>
                                        <div class="col-10 col-md-10">
                                            <p class="mb-0 text-truncate chart-assets-item"> Employee Assets
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 ">
                                    <div class=" row mt-4  d-flex align-items-center">
                                        <div class="col-1 col-md-1">
                                            <i
                                                    class="mdi mdi-circle green font-size-10 mr-1"></i>
                                        </div>
                                        <div class="col-10 col-md-10">
                                            <p class=" text-truncate mb-0 chart-assets-item text-wrap">Potential Phishing Domains
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 ">
                                    <div class=" row mt-4 d-flex align-items-center">
                                        <div class="col-1 col-md-1">
                                            <i class="mdi mdi-circle green-light font-size-10 mr-1"></i>
                                        </div>
                                        <div class="col-10 col-md-10">
                                            <p class="mb-0 text-truncate chart-assets-item"> Breached Data
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <div class="col-md-5">
            <div class="card main-card">
                <div class="card-body">
                    <!--<h4 class=" mb-4 pl-2 ml-1 chart-heading">Assets By Countries</h4>-->
                  <div class="d-flex justify-content-center">
                    <div class="p-2"><h4 class="  chart-heading">Assets By Countries</h4></div>
                    <div class="ml-auto p-2 pointer d-none d-sm-none d-md-block" matTooltip="Here you can see the Ip server data" matTooltipPosition="above">
                      <i class="info-icon" ></i>
                    </div>
                    <div class="ml-auto p-2 pointer d-block d-sm-block d-md-none d-lg-none d-xl-none" #tooltip="matTooltip"
                         matTooltip="Here you can see the Ip server data" matTooltipPosition="above" (click)="tooltip.toggle()" >
                      <i class="info-icon" ></i>
                    </div>
                  </div>
                    <div class="table-responsive" >
                        <table class="table table-borderless table-centered scrolldown" >
                            <thead class=" col-12 pb-2">
                            <tr>
                                <th class=" pt-0 pl-0">Country</th>
                                <th class=" pt-0 pl-0 move-left">Web Assets</th>
                                <th class=" pt-0 ">Potential Phishing Domains</th>
                            </tr>
                            </thead>

                            <tbody class="overflow-auto text-left">
                            <tr *ngFor="let item of assetsByCountryData">
                                <td class=" row pt-0"><div ><img style="display:inline;"
                                        [src]="item.flagUrl"
                                        style="height: 24px;width:24px;object-fit: contain;padding-right: 5px"> </div>
                                  <span class="text-wrap" style="max-width:120px;">{{item.country}}</span>
                                </td>
                                <td class="pt-0" >{{item.webAssets}}</td>
                                <td class="pt-0">{{item.phishingDomainAssets}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
      

    </div>
</div>

