import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {environment} from '../environments/environment';

import {LayoutsModule} from './layouts/layouts.module';
import {PagesModule} from './pages/pages.module';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {initFirebaseBackend} from './authUtils';

import {ErrorInterceptor} from './core/helpers/error.interceptor';
import {AuthGuard} from "./core/guards/auth.guard";
import {StorageServiceModule} from "ngx-webstorage-service";
import {TokenInterceptor} from "./auth/token.interceptor";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IconsModule} from "./layouts/shared/icons.module";
import {DeactivateGuard} from "./auth/deactivate.guard";
import {AlertModule} from "./shared/alert/alert.module";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {Ng2OrderModule} from "ng2-order-pipe";


// if (environment.defaultauth === 'firebase') {
//   initFirebaseBackend(environment.firebaseConfig);
// }

export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,

    ],
    imports: [
        BrowserModule,
        // NgxGoogleAnalyticsModule.forRoot('UA-213423390-1'),
        // NgxGoogleAnalyticsRouterModule,
        HttpClientModule,
        AppRoutingModule,
        PagesModule,
        LayoutsModule,
        StorageServiceModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        AlertModule,
      Ng2OrderModule,


    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        AuthGuard,
        DeactivateGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
