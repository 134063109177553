import {Injectable} from '@angular/core';
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0
    });
    monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'];
    monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
        'Sep', 'Oct', 'Nov', 'Dec'];

    constructor() {
    }

    public removeAppComponents() {
        document.getElementById('mainPanel').classList.add('p-0');
        document.getElementById('pageBodyWrapper').classList.add('p-0');
        document.getElementById('sideNavBar')?.remove();
        document.getElementById('settingsPanel')?.remove();
        document.getElementById('navBar')?.remove();
        document.getElementById('appFooter')?.remove();
        document.querySelector('.main-panel').classList.add('w-100');
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        document.querySelector('.content-wrapper').classList.add('p-0');


    }

    public handleError(error: any) {
        const errorResponse = error.error;
        // console.error('handleError', errorResponse);
        return {
            status: errorResponse._error ? errorResponse._error.errorCode : errorResponse.status,
            message: errorResponse._error ? errorResponse._error.detailMessage || errorResponse._error.message : errorResponse.message
        };

    }

    public getDateFormat(dateObj: any): string {
        return dateObj.year ? dateObj.year + '-'
            + dateObj.month.toString().padStart(2, '0') + '-'
            + dateObj.day.toString().padStart(2, '0') : '';
    }

    getWeeekStartAndEndDate(date) {
        let weekMap = [0, 1, 2, 3, 4, 5, 6];

        let now = new Date(date);
        now.setHours(0, 0, 0, 0);
        let monday = new Date(now);
        monday.setDate(monday.getDate() - weekMap[monday.getDay()]);
        let sunday = new Date(now);
        sunday.setDate(sunday.getDate() - weekMap[sunday.getDay()] + 6);
        sunday.setHours(23, 59, 59, 999);
        // return [monday.toLocaleDateString(), sunday.toLocaleDateString()];

        //converting to our date format
        let mt = monday.toLocaleDateString('en-US').split('/');
        let startDate = mt[2] + '-' + mt[0] + '-' + mt[1];
        let st = sunday.toLocaleDateString('en-US').split('/');
        let endDate = st[2] + '-' + st[0] + '-' + st[1];
        return [startDate, endDate];
    }

    public showCurrencyValue(value) {
        return this.formatter.format(Math.floor(value));
    }

    public showCommaSeparateCurrency(value) {
        return Number((value).toFixed(0)).toLocaleString();
    }

    public getLast6Months() {

        let last6Months: any[] = [];


        let today = new Date();
        let d;
        let month;

        for (let i = 5; i > -1; i -= 1) {
            d = new Date(today.getFullYear(), today.getMonth() - i, 1);
            month = this.monthNames[d.getMonth()];
            last6Months.push(month);
        }
        last6Months.reverse();
        return last6Months;
    }

    public getLast6MonthsWithYearObj() {

        let last6Months: any[] = [];


        let today = new Date();
        let d;
        let monthName;

        for (let i = 5; i > -1; i -= 1) {
            d = new Date(today.getFullYear(), today.getMonth() - i, 1);
            monthName = this.monthNames[d.getMonth()];
            last6Months.push({
                monthName: monthName,
                yearAndMonthName: d.getFullYear() + ' - ' + monthName,
                monthShortName: this.monthShortNames[d.getMonth()] + ' \'' + d.getFullYear().toString().substr(-2),
                year: d.getFullYear(),
                month: +d.getMonth() + 1
            });
        }
        last6Months.reverse();
        return last6Months;
    }

    public getLastDayOfMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }

    trim(x) {
        return x.replace(/^\s+|\s+$/gm, '');
    }

    generateHttpParams(obj: any) {
        let params = new HttpParams();

        for (var propName in obj) {
            let propValue = obj[propName];
            if (propValue) {
                params = params.append(propName, propValue);
            }
        }
        return params;
    }

    /**
     * This function returns the financial year dates object ex:  { periodFrom: '2019-04-01', periodTo: '2020-03-31'}
     */
    getCurrentFinancialYearDates() {
        let today = new Date();
        let periodFrom = '';
        let periodTo = '';
        if ((today.getMonth() + 1) <= 3) {
            periodFrom = (today.getFullYear() - 1) + '-04-01';
            periodTo = today.getFullYear() + '-03-31';

        } else {
            periodFrom = today.getFullYear() + '-04-01';
            periodTo = (today.getFullYear() + 1) + '-03-31';
        }
        return {periodFrom: periodFrom, periodTo: periodTo};
    }

    stripDomainUrl(value: string) {
        let urlPrefix = '';
        if (value.includes('https')) {
            urlPrefix = 'https://';
        } else if (value.includes('http')) {
            urlPrefix = 'http://';
        } else {
            urlPrefix = 'https://';
        }
        if (value.includes('//')) {
            value = value.split('//')[1];
        }
        if (value.includes('/')) {
            value = value.split('/')[0];
        }
        if (value.includes('www.')) {
            value = value.split('www.')[1];
        }
        return {urlPrefix: urlPrefix, domainUrl: value};
    }

}
