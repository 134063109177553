import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {Browser, latLng, tileLayer} from 'leaflet';
import {NgxSpinnerService} from "ngx-spinner";

import {ChartType, Stat, Chat, Transaction} from './dashboard.model';

import {
  statData,
  revenueChart,
  salesAnalytics,
  sparklineEarning,
  sparklineMonthly,
  chatData,
  transactions
} from './data';
import {UserService} from "../../core/services/user.service";
import {AlertService} from "../../shared/alert/services/alert.service";
import {LOCAL_STORAGE, WebStorageService} from "ngx-webstorage-service";
import {Router} from "@angular/router";
import {finalize, map} from "rxjs/operators";
import {ApexResponsive} from "ng-apexcharts";
import pointer = Browser.pointer;
import {MatTooltip} from "@angular/material/tooltip";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],


})


/**
 * Dashboard Component
 */

export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {


  // term: any;
  // chatData: Chat[];
  // transactions: Transaction[];
  // statData: Stat[];
  //
  // // bread crumb items
  // breadCrumbItems: Array<{}>;
  //
  // revenueChart: ChartType;
  // sparklineEarning: ChartType;
  // sparklineMonthly: ChartType;
  //
  // // Form submit
  // chatSubmit: boolean;
  //
  // formData: FormGroup;
  //
  //
  // options = {
  //     layers: [
  //         tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
  //     ],
  //     zoom: 6,
  //     center: latLng(46.879966, -121.726909)
  // };


  riskByCategoryAnalysis: ChartType;
  comparativeSecurityAnalysis: any;
  user: any;
  userId: string = '';
  webAssetsPercentageCount = 0;
  employeeAssetsPercentageCount = 0;
  phishingDomainPercentageCount = 0;
  brechedDataPercentageCount = 0;
  percentageCount: any[] = [];
  subDomainsCount: any = 0;
  employeeAssets: any = 0;
  phishingDomainsCount: any = 0;
  sourceCodeLeakageCount: any = 0;
  search = {query: '', page: 0, size: 50};


  //assets timeline
  latestScansSummaryData: any[] = [];
  webAssetsData: any[] = [];
  employeeAssetsData: any[] = [];
  phishingDomainData: any[] = [];
  brechedData: any[] = [];
  domainUrls: any[] = [];
  assetsTimelineChartData: any;

  //Assets by country
  assetsByCountryData: any[] = [];


  constructor(public formBuilder: FormBuilder, private userService: UserService, private alertService: AlertService,
              @Inject(LOCAL_STORAGE) private storage: WebStorageService, private router: Router,
              private spinner: NgxSpinnerService,) {

  }

  ngOnDestroy(): void {
    let d = document.getElementById('layout-wrapper');
    d.style.setProperty('background-color', '#FFFFFF');
  }

  ngAfterViewInit(): void {
    let d = document.getElementById('layout-wrapper');
    d.style.setProperty('background-color', '#F6F6F7');
  }

  date = new Date();

  ngOnInit(): void {
    this.user = this.storage.get('loggedInUser');
    this.userId = this.user['_id'];
    this._fetchData();
  }

  private _fetchData() {
    this.processRiskByCategory();
    this.getMonthCount();
  }


  /**
   * Returns form
   */
  // get form() {
  //     return this.formData.controls;
  // }

  /**
   * Save the message in chat
   */

  private processRiskByCategory() {
    let count = 0;
    this.spinner.show();
    this.userService.getDashboardData(this.userId).pipe(finalize(() => this.spinner.hide())).subscribe((data) => {
      if (!this.subDomainsCount) {
        this.subDomainsCount = data.assetsRecordsCounts.subDomainsCount;
      }
      if (!this.employeeAssets) {
        this.employeeAssets = data.assetsRecordsCounts.employeeAssets;
      }
      if (!this.phishingDomainsCount) {
        this.phishingDomainsCount = data.assetsRecordsCounts.phishingDomainsCount;
      }
      if (!this.sourceCodeLeakageCount) {
        this.sourceCodeLeakageCount = data.assetsRecordsCounts.sourceCodeLeakageCount;
      }

      if (data.latestScansSummaryData && data.latestScansSummaryData.length) {
        this.latestScansSummaryData = data.latestScansSummaryData;
        this.latestScansSummaryData.forEach((item, index) => {
          // if (index > 2) {
          //     return;
          // }

          this.webAssetsData.push(item.subDomainsCount);
          this.employeeAssetsData.push(item.employeeAssets);
          this.phishingDomainData.push(item.phishingDomainsCount);
          this.brechedData.push(item.sourceCodeLeakageCount);
          let domainUrl = '';
          if (item.baseDomainDetails && item.baseDomainDetails.baseDomain) {
            if (String(item.baseDomainDetails.baseDomain).indexOf('//')) {
              domainUrl = item.baseDomainDetails.baseDomain.split('//')[1];
            } else {
              domainUrl = item.baseDomainDetails.baseDomain;
            }
          }
          this.domainUrls.push(domainUrl.split(".")[0]);
        });
        this.processAssetsTimeLineChart();
        this.processAssetsByCountryData(data);
      }

      count = data.assetsRecordsCounts.subDomainsCount + data.assetsRecordsCounts.employeeAssets + data.assetsRecordsCounts.phishingDomainsCount
        + data.assetsRecordsCounts.sourceCodeLeakageCount;
      // this.totalDataCount = count;
      if (data.assetsRecordsCounts.subDomainsCount)
        this.webAssetsPercentageCount = +((data.assetsRecordsCounts.subDomainsCount / count) * 100).toFixed(2);
      if (data.assetsRecordsCounts.employeeAssets)
        this.employeeAssetsPercentageCount = +((data.assetsRecordsCounts.employeeAssets / count) * 100).toFixed(2);
      if (data.assetsRecordsCounts.phishingDomainsCount)
        this.phishingDomainPercentageCount = +((data.assetsRecordsCounts.phishingDomainsCount / count) * 100).toFixed(2);
      if (data.assetsRecordsCounts.sourceCodeLeakageCount)
        this.brechedDataPercentageCount = +((data.assetsRecordsCounts.sourceCodeLeakageCount / count) * 100).toFixed(2);

      this.percentageCount.push(this.webAssetsPercentageCount);
      this.percentageCount.push(this.employeeAssetsPercentageCount);
      this.percentageCount.push(this.phishingDomainPercentageCount);
      this.percentageCount.push(this.brechedDataPercentageCount);

      this.riskByCategoryAnalysis = {
        series: [this.webAssetsPercentageCount, this.employeeAssetsPercentageCount,
          this.phishingDomainPercentageCount, this.brechedDataPercentageCount],
        chart: {
          height: 280,
          type: 'donut',
          width: '100%',
          states: {
            active: {
              filter: {
                type: 'none' /* none, lighten, darken */
              }
            }
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]


        },
        labels: ["Web Assets", "Employee Assets", "Phishing Domains", "Breached Data"],
        plotOptions: {

          pie: {
            donut: {
              size: '75%'
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false,
        },
        colors: ['#0090E7', '#99D3F5', '#1BC59C', '#A4E8D7'],

      };
    }, error => {
      // console.error(error);
      console.log(error);
      this.alertService.error(error ? error['message'] : '');
    });
  }

  comparativeSecurityData: any[] = [];
  scan_records : any[] =[];
  web_assets_records: any = 0;
  count: any[] = [];
  employee_assets_records : any =0;
  phishing_assets_records: any = 0;
  databreach_assets_records: any =0;


  public getMonthCount() {
    // setTimeout(() => this.spinner.show(), 100);
    this.userService.getDashboardComparativeCount().subscribe(
      (res: any) => {
        this.comparativeSecurityData = res.scan_records;

        this.comparativeSecurityAnalysis = {
          series: [
            {
              data: this.comparativeSecurityData.map(records=> records.web_assets_records),
              name: 'Web Assets',
            },
            {
              data: this.comparativeSecurityData.map(records=> records.employee_assets_records),
              name: 'Employee Assets',
            },
            {
              data: this.comparativeSecurityData.map(records=> records.phishing_assets_records),
              name: 'Phishing Domains',
            },
            {
              data:  this.comparativeSecurityData.map(records=> records.databreach_assets_records),
              name: 'Breached Data',
            },
          ],
          chart: {
            type: 'bar',
            height: 218,
            width: '700',
            background: 'transparent',
            states: {
              hover: {
                filter: {
                  type: 'none',
                }
              },
            },
            toolbar: {
              show: false
            }
          },

          responsive: [
            {
              breakpoint: 1000,
              options: {
                plotOptions: {
                  bar: {
                    horizontal: false
                  }
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ],

          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },

          },

          // responsive: true,
          maintainAspectRatio: false,
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: this.comparativeSecurityData.map(records=> records.duration),
            labels: {
              show: true,
              style: {
                color: '#000000',
                fontSize: '12px'
              },
            }, axisBorder: {
              show: true,
              color: '#E8E8E8',
              height: 2,
              width: '100%',
              offsetX: 0,
              offsetY: 0
            },

          },
          yaxis: {
            labels: {
              style: {
                colors: '#111'
              }
            }, axisBorder: {
              show: true,
              color: '#E8E8E8',
              width: 2,
              height: '100%',
              offsetX: 0,
              offsetY: 0
            },
          },
          fill: {
            opacity: 1
          },
          colors: ['#0090E7', '#99D3F5', '#1BC59C', '#A4E8D7'],
          legend: {
            show: false,
          },
        };

      }, error => {
        // console.error(error);
        this.alertService.error(error ? error['message'] : '');

      });
  }

  private processAssetsTimeLineChart() {


    this.assetsTimelineChartData = {
      series: [
        {
          data: this.webAssetsData,
          name: 'Web Assets',
        },
        {
          data: this.employeeAssetsData,
          name: 'Employee Assets',
        },
        {
          data: this.phishingDomainData,
          name: 'Phishing Domains',
        },
        {
          data: this.brechedData,
          name: 'Breached Data',
        },
      ],
      chart: {
        type: 'bar',
        height: 319,
        width: '450',
        stacked: true,
        toolbar: {
          show: false
        },
        events: {
          click: (event, chartContext, config) => {

            // let index;
            // if (+config.dataPointIndex < 0) {
            //   index = 0;
            // } else {
            //   index = config.dataPointIndex;
            // }
            // console.log(this.latestScansSummaryData[index]);
            // this.storage.set('currentScanRow',
            //   this.scansData.filter(scan => scan['_id'] == this.latestScansSummaryData[config.dataPointIndex].baseDomainDetails.scanId)[0]);
            if(!this.latestScansSummaryData[config.dataPointIndex]){
              return
            }
            this.router.navigate(['/scans/' + this.latestScansSummaryData[config.dataPointIndex].baseDomainDetails.scanId + '/results-page']);

          //   events: {
          // click: (event, chartContext, config) => {
          //   console.log(config.seriesIndex);
          //   console.log(config.dataPointIndex);
          //
          //   let index;
          //   if (+config.dataPointIndex < 0) {
          //     index = 0;
          //   } else {
          //     index = config.dataPointIndex;
          //   }
          //   // console.log(this.latestScansSummaryData[index]);
          //   this.storage.set('currentScanRow',
          //     this.scansData.filter(scan => scan['_id'] == this.latestScansSummaryData[index].baseDomainDetails.scanId)[0])
          //   this.router.navigate(['/scans/' + this.latestScansSummaryData[index].baseDomainDetails.scanId + '/results-page']);
            // {
            //     queryParams: {
            //         createdOn: this.latestScansSummaryData[index].baseDomainDetails.createdOn,
            //         selectedScanOptions: 'WebAssets,EmployeeAssets,PotentialPhishingDomains,DataBreach'
            //     }
            // });


            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
          }
        }
      },

      responsive: [
        {
          breakpoint: 1000,
          options: {
            plotOptions: {
              bar: {
                horizontal: false
              }
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },

      },
      maintainAspectRatio: false,
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },

      xaxis: {
        categories: this.domainUrls ,
        labels: {
          show: true,
          style: {
            color: '#000000',
            fontSize: '12px'

          },
        }, axisBorder: {
          show: true,
          color: '#E8E8E8',
          height: 2,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },

      },
      yaxis: {
        labels: {
          style: {
            colors: '#111'
          }
        }, axisBorder: {
          show: true,
          color: '#E8E8E8',
          width: 2,
          height: '100%',
          offsetX: 0,
          offsetY: 0
        },
      },
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true
        }]
      },

      fill: {
        opacity: 1
      },
      colors: ['#0090E7', '#99D3F5', '#1BC59C', '#A4E8D7'],
      legend: {
        show: false,
      },
    };
  }

  private processAssetsByCountryData(data) {
    let webAssetsByCountry = data.assetsByCountry;
    if (webAssetsByCountry) {
      let allCountryObjs = [];
      //  adding all objects to array
      if (webAssetsByCountry.webAssets && webAssetsByCountry.webAssets.length) {
        for (let obj of webAssetsByCountry.webAssets) {
          allCountryObjs.push(obj);
        }
      }
      if (webAssetsByCountry.phishingDomains && webAssetsByCountry.phishingDomains.length) {
        for (let obj of webAssetsByCountry.phishingDomains) {
          allCountryObjs.push(obj);
        }
      }

      const distinctItems = [...new Map(allCountryObjs.map(item => [item["country"], item])).values()];
      for (let item of distinctItems) {
        this.assetsByCountryData.push({
          'country': item['country'],
          'flagUrl': item['flagUrl'],
          'webAssets': webAssetsByCountry.webAssets.filter(c => c['country'] == item['country'])[0]?.count || '0',
          'phishingDomainAssets': webAssetsByCountry.phishingDomains.filter(c => c['country'] == item['country'])[0]?.count || '0',
        })
        ;
      }
      this.assetsByCountryData = this.assetsByCountryData.sort((a, b) => a.webAssets > b.webAssets ? -1 : 1);

    }
  }


  onRiskByCategoryBarClick(event) {
    console.log(event);
  }
}
