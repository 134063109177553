import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgbDropdownModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {ClickOutsideModule} from 'ng-click-outside';
import {LanguageService} from '../../core/services/language.service';
import {TranslateModule} from '@ngx-translate/core';

import {TopbarComponent} from './topbar/topbar.component';
import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {RightsidebarComponent} from './rightsidebar/rightsidebar.component';
import {HorizontaltopbarComponent} from './horizontaltopbar/horizontaltopbar.component';
import {HorizontalnavbarComponent} from './horizontalnavbar/horizontalnavbar.component';
import {LaunchScanDialogComponent} from "../../pages/scans/launch-scan-dialog/launch-scan-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
    // tslint:disable-next-line: max-line-length
    declarations: [TopbarComponent, FooterComponent, SidebarComponent, RightsidebarComponent, HorizontaltopbarComponent, HorizontalnavbarComponent],
    imports: [
        CommonModule,
        TranslateModule,
        PerfectScrollbarModule,
        NgbDropdownModule,
        ClickOutsideModule,
        RouterModule,
        MatDialogModule,
      NgbProgressbarModule
    ],
    exports: [TopbarComponent, FooterComponent, SidebarComponent, RightsidebarComponent,
        HorizontaltopbarComponent, HorizontalnavbarComponent],
    providers: [LanguageService],
    entryComponents: [LaunchScanDialogComponent]
})
export class SharedModule {
}
