import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from "../../core/services/user.service";
import {LOCAL_STORAGE, WebStorageService} from "ngx-webstorage-service";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  message='';
  isShowBanner :boolean =false;

  constructor( private userService: UserService, @Inject(LOCAL_STORAGE) private storage: WebStorageService) { }

  ngOnInit(): void {
    if (this.storage.get("isFromLogin")) {
      this.isShowBanner=true;
      this.getNewFeatureNotifications();
      this.storage.remove("isFromLogin");
    }

  }

  getNewFeatureNotifications() {
    this.userService.getUserPushNotifications().subscribe(
      (res: any) => {
        if (res) {
          // checking expiry date
          let currentDate = new Date();
          let expiryDate = new Date(res.expires_on);
          // console.log(expiryDate.getTime());
          if (expiryDate.getTime() > currentDate.getTime()) {
            this.message = res.text;
            // console.log(this.message);
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onClick(): void {
    this.isShowBanner =false;
  }

}
