import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {DashboardComponent} from './dashboard/dashboard.component';
import {importExpr} from "@angular/compiler/src/output/output_ast";
import {AuthGuard} from "../core/guards/auth.guard";

const routes: Routes = [


  {path: '', redirectTo: 'dashboard' ,pathMatch: 'full'},

    {path: 'dashboard', component: DashboardComponent},
    // {path: 'pages', loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule)},
    {
        path: 'scans', canActivate: [AuthGuard], loadChildren: () => import('./scans/scans.module').then(m => m.ScansModule)
    },
    { path:'compliance', canActivate: [AuthGuard], loadChildren: () => import('./compliance-health/compliance-health.module').then(m => m.ComplianceHealthModule)},
    {path:'agdsgsgkadgksdgsagkkgdgsak', canActivate: [AuthGuard], loadChildren: () => import('./member-management/member-management.module').then(m => m.MemberManagementModule)},
    {path:'web-security', canActivate: [AuthGuard], loadChildren: () => import('./web-security/web-security.module').then(m=>m.WebSecurityModule)},
    {path:'cloud-security', canActivate: [AuthGuard], loadChildren: () => import('./cloud-security/cloud-security.module').then(m=>m.CloudSecurityModule)},
    {path: 'smart-contract-audit', canActivate:[AuthGuard], loadChildren: () => import('./smart-contract-audit/smart-contract-audit.module').then(m=>m.SmartContractAuditModule)}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {
}
