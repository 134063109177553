// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    defaultauth: 'fackbackend',
    firebaseConfig: {
        apiKey: '',
        authDomain: '',
        databaseURL: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
        measurementId: ''
    },
    production: false,
    /* new staging */
    // API_URL: 'http://52.172.250.245:43210',
    isLocalDev: false,
    /* enable this for production */

    captchaKey: "6LfaTmMcAAAAALrWeCuwwGYWlCU1Bgmm_Ih12V1Y",
    API_URL: 'https://app.getsecured.ai:1236',

    /* enable this for old staging server */

    // captchaKey: "6LdiAZwjAAAAAPPa3VK-wFj5qBhP3viSn0R6YwJt",
    // API_URL: 'http://52.220.92.62:1236',

    invisibleCaptchaKey: '6Lc3JHAcAAAAAGS5TTW9H4IvbKeU3Cpu6ooN-oNf',
    razorpayKey: 'rzp_test_L896cRPRgHCpMv',
    supportEmail: 'support@getsecured.ai'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
