import {Component, Inject, OnInit} from '@angular/core';
import {ScansService} from "../../../core/services/scans.service";
import {Router} from "@angular/router";
import {AlertService} from "../../../shared/alert/services/alert.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {finalize} from "rxjs/operators";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CommonService} from "../../../core/services/common.service";
import {LaunchPopupService} from "../../../core/services/launch-popup.service";
import {environment} from "../../../../environments/environment";
import {LOCAL_STORAGE, WebStorageService} from "ngx-webstorage-service";

@Component({
  selector: 'app-launch-scan-dialog',
  templateUrl: './launch-scan-dialog.component.html',
  styleUrls: ['./launch-scan-dialog.component.scss']
})
export class LaunchScanDialogComponent implements OnInit {
  dataForm: FormGroup;
  scanOptions: any[] = [
    {title: 'Web Assets (Mandatory)', code: 'WebAssets', checked: true, disabled: true},
    {title: 'Employee Assets', code: 'EmployeeAssets', checked: false, disabled: false},
    {title: 'Potential Phishing Domains', code: 'PotentialPhishingDomains', checked: false, disabled: false},
    {title: 'Data Breach', code: 'DataBreach', checked: false, disabled: false},
  ];
  disabledSubmit = false;
  urlPrefix: any = 'https://';
  isInvalidDomain: any;
  errorMsg = '';

  isLoading = false;
  isSubmitted: boolean = false;
  submitBtntext: any = 'Launch Scan';

  constructor(private fb: FormBuilder, private alertService: AlertService, private scansService: ScansService,
              private router: Router, public dialogRef: MatDialogRef<LaunchScanDialogComponent>,
              private commonService: CommonService, public dialog: MatDialog, private launchPopupService: LaunchPopupService,
              @Inject(LOCAL_STORAGE) private storage: WebStorageService) {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.buildDataForm();
  }

  private buildDataForm() {
    // this.spinner.show();
    // setTimeout(() =>{this.spinner.show()}, 500);
    this.dataForm = this.fb.group({
      domainUrl: ['', Validators.compose([Validators.required,
        Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)])],
      isAgreedOwnerOfTheDomain: ['', Validators.required]
    });
  }

  onLaunchScan(isSheduledScan: boolean) {
    if (this.dataForm.invalid) {
      this.alertService.error('Please fill all required details');
      return;
    }
    let selectedScanOptions = this.scanOptions.filter(so => so.checked);
    if (!selectedScanOptions || !selectedScanOptions.length) {
      this.alertService.error('Please select at least one option to scan');
      return;
    }

    this.validateDomainAndLaunchScan(selectedScanOptions, isSheduledScan);
  }


  private validateDomainAndLaunchScan(selectedScanOptions: any[], isScheduledScan: boolean) {
    let search = {domain_url: this.urlPrefix + this.dataForm.value.domainUrl};
    this.errorMsg = '';
    this.isLoading = true;
    this.submitBtntext = 'Validating url';
    this.scansService.validateDomain(search).pipe(finalize(() => this.isLoading = false)).subscribe(
      (res: any) => {
        let payload = {
          domainUrl: this.urlPrefix + this.dataForm.value.domainUrl,
          scanOptions: selectedScanOptions,
          isSheduledScan: isScheduledScan,
          sheduledTime: '',
        };
        this.disabledSubmit = true;
        setTimeout(() => {
          this.isLoading = true;
        }, 500);
        this.submitBtntext = 'Launching Scan';
        this.scansService.launchScan(payload).pipe(finalize(() =>
          setTimeout(() => this.isLoading = false, 500))).subscribe(
          (res: any) => {
            this.alertService.success(res['message']);
            setTimeout(() => {
              this.dataForm.reset();
              this.dialogRef.close(true);
              // this.router.navigate(['/scans']);
            }, 1000);
          }, error => {
            this.submitBtntext = 'Launch Scan';
            console.error(error);
            this.errorMsg = error['message'];
            this.isLoading = false;
            this.errorMsg = error['message'];
            this.alertService.error(error['message']);
          }
        );
      }, error => {
        this.submitBtntext = 'Launch Scan';
        this.isLoading = false;
        this.disabledSubmit = false;
        this.errorMsg = error['message'];
        console.error(error);
        this.isInvalidDomain = true;
        this.alertService.error(error['message']);
      }
    );

  }

  onDomainUrlChange(value: string) {
    let data = this.commonService.stripDomainUrl(value);
    this.urlPrefix = data['urlPrefix'];
    this.dataForm.get('domainUrl').setValue(data['domainUrl']);

  }


  openNavLaunchPopup() {
    this.launchPopupService.infoClickEmmiter.emit('OPEN')
  }

  onContactSupportClick() {
    let platform = this.getOS();
    if (platform == 'Android' || platform == 'Mac OS' || platform == 'iOS') {
      console.log( this.storage.get('loggedInUser').userEmail );
      let link = 'mailto:' + environment.supportEmail + '?subject=Request for Manual scan&body=%0d%0a %0d%0aUserEmail:' + this.storage.get('loggedInUser').userEmail + '%0d%0a %0d%0a ';
      let win = window.open(link, '_blank');
      win.focus();
      setTimeout(() => win?.close(), 2000);
    } else {
      let link = 'https://mail.google.com/mail/u/' +
        '/?view=cm&fs=1&to=' + environment.supportEmail + '&su=Request for Manual scan&body=%0d%0a %0d%0aUserEmail:' + this.storage.get('loggedInUser').userEmail + '%0d%0a %0d%0a ';
      console.log(link);
      let win = window.open(link, '_blank');
      win.focus();
    }
  }

  getOS() {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

}
