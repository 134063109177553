<div class="card">
  <div class="card-body">
    <div class="media">
      <div class="media-body overflow-hidden">
        <p class="text-truncate font-size-14 mb-2">{{title}}</p>
        <h4 class="mb-0">{{value}}</h4>
      </div>
      <div class="text-primary">
        <i class="{{icon}} font-size-24"></i>
      </div>
    </div>
  </div>

  <div class="card-body border-top py-3">
    <div class="text-truncate">
      <span class="badge badge-soft-success font-size-11"><i class="mdi mdi-menu-up"> </i> 2.4% </span>
      <span class="text-muted ml-2">From previous period</span>
    </div>
  </div>
</div>
