<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">

  <!--<perfect-scrollbar>-->
      <!--- Sidemenu -->
      <div id="sidebar-menu">
          <!-- Left Menu Start -->
          <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>

              <ng-container *ngFor="let item of menuItems">
                  <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
                  <!-- Layouts menu -->

                  <!-- end Layout menu -->

                  <li *ngIf="!item.isTitle && !item.isLayout">
                      <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                         [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                          <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                          <span> {{ item.label | translate }}</span>
                          <span class="badge badge-pill badge-{{item.badge.variant}} float-right"
                                *ngIf="item.badge">{{item.badge.text | translate}}</span>
                      </a>

                      <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref"
                         routerLinkActive="active" #rla="routerLinkActive">
                          <i class="bx " [ngClass]="rla.isActive?item.icon+'-active':item.icon"
                             *ngIf="item.icon"></i>
                          <span> {{ item.label | translate }}</span>
                          <span class="badge badge-pill badge-{{item.badge.variant}} float-right"
                                *ngIf="item.badge">{{item.badge.text | translate}}</span>
                      </a>

                      <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                          <li *ngFor="let subitem of item.subItems">
                              <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                                 [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                                  {{ subitem.label | translate }}
                              </a>
                              <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow"
                                 href="javascript:void(0);"
                                 [attr.data-parent]="subitem.parentId">
                                  {{ subitem.label | translate }}
                              </a>
                              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                                  <li *ngFor="let subSubitem of subitem.subItems">
                                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                                         routerLinkActive="active"
                                         class="side-nav-link-ref">
                                          {{ subSubitem.label | translate }}
                                      </a>
                                  </li>
                              </ul>
                          </li>
                      </ul>
                  </li>
              </ng-container>
          </ul>
      </div>
      <!-- Sidebar -->
  <!--</perfect-scrollbar>-->
</div>
<!-- Left Sidebar End -->
