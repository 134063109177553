export const apiConfig = {
    auth: {
        login: '/login',
        register: '/register',
        tmpRegister: '/tmp/user/register',
        tmpRegisterUpdate: '/tmp/user/register/update',
        verifyEmail: '/verify/email',
        resendVerifyEmail: '/verify/email/resend',
        loginV2: '/login/v2',
        loginTokenRequest: '/login/request'
    },
    scan: {
        launchScan: '/request/scan',
        getUserScans: '/scans',
        getUserTeamScans: '/scans/user/team',
        summary: '/summary',
        validateDomain: '/validate/domain',
        reinitiate: '/reinitiate'
    },
    user: {
        getUser: '/user',
        dashboard: '/dashboard/data',
        userTeamDashboard: '/user/team/dashboard',
        getUserControls: '/user/controls',
        logout: '/logout',
    },
    teamUsers: {
        getTeamUsers: '/user/team',
        addTeamUser: '/user/team/add',
        updateTeamUser: '/user/team/update',
        deleteTeamUser: '/user/team/remove'
    },
    subscription: {
        subscriptions: '/subscriptions',
        buy: '/buy',
        validatePayment: '/validate/payment',
        userSubscriptions: '/user/subscriptions'
    },
    compliance: {
      scan: '/compliance/scan'
    },

    cloudSecurity:{
      launchScan: '/request/scan/cloud',
      getUserTeam: '/scans/cloud/user/team',
      getUserScans :'/scans',
      cloudSecurity:'/cloudSecurity'
    },
    webSecurity:{
      launchWebScan:'/request/scan/websecurity',
      getUserTeam: '/scans/websecurity/user/team',
      getUserScans :'/scans',
      webSecurity:'/websecurity'

    },
    scanLimit:{
      user:'/user/usage_data'
    },
    scanOption:{
      scan:'/scans',
      option:'/options'
    },
    comparative:{
      scans:'/scans/user/scan_records'
    },
  banner:{
    userPushNotifications: "/user/push_notification",
  }
};
