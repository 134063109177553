/**
 * Created by Ganesh on 4/17/2018.
 */

export class Alert {
    type: AlertType;
    message: string;
    id: string;
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}
