<footer class="footer" *ngIf="false">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                2021 © Chitragupta.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-right d-none d-sm-block">
                    Crafted with <i class="mdi mdi-heart text-danger"></i> by team @DetaSecure
                </div>
            </div>
        </div>
    </div>
</footer>
