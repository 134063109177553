import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';

import {LayoutComponent} from './layouts/layout/layout.component';
import {MemberManagementComponent} from "./pages/member-management/member-management.component";
import {WebSecurityReportComponent} from "./pages/web-security/web-security-report/web-security-report.component";


const routes: Routes = [
    {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    {path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},
    // tslint:disable-next-line: max-line-length
    {
        path: '',
        component: LayoutComponent,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'pages',
        loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule),
        canActivate: [AuthGuard]
    },
    // {path:'web-security-report', component:WebSecurityReportComponent},
    // {path:'launch-scan', component:LaunchScanComponent },

    {path: 'login', redirectTo: 'account/login'},
    {path: 'register', redirectTo: 'account/register'},
    {path: 'verifyemail', redirectTo: 'account/verifyemail'},
    {path: '**', redirectTo: '/pages/404'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
