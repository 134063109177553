import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {User} from '../models/auth.models';
import {LOCAL_STORAGE, WebStorageService} from "ngx-webstorage-service";
import {CommonService} from "./common.service";
import {environment} from "../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {throwError} from "rxjs";
import {apiConfig} from "../../api-url.config";

@Injectable({providedIn: 'root'})
export class UserService extends CommonService {

    API_END_POINT: string;
    canRouteChangeable = false;

    constructor(private httpClient: HttpClient, @Inject(LOCAL_STORAGE) private storage: WebStorageService,) {
        super();
        this.API_END_POINT = environment.API_URL;
    }

    loginUser(value: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.auth.login}`, value)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    registerUser(value: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.auth.register}`, value)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    verifyEmail(payload: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.auth.verifyEmail}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    resendVerificationEmail(value: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.auth.resendVerifyEmail}`, value)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    loginUserV2(param: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.auth.loginV2}`, param)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );

    }

    loginTokenRequest(value: any) {
        let params = new HttpParams();
        if (environment.isLocalDev) {
            params = this.generateHttpParams({isDev: true});
        }
        // console.log(params);
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.auth.loginTokenRequest}`, value, {params})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );

    }

    registerTmpUser(payload: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.auth.tmpRegister}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    launchScan(payload: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.scan.launchScan}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getUserScans(userId: any, search: any) {
        let params = this.generateHttpParams(search);
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scan.getUserTeamScans}`, {params})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );

    }

    updateTmpUser(value: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.auth.tmpRegisterUpdate}`, value)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );

    }

    getScanDetailsData(scanId: string, scanOptionCode: string, search: { page: number; size: number }) {
        let params = this.generateHttpParams(search);
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scan.getUserScans}/${scanId}/${scanOptionCode}`, {params})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getScanSummaryData(scanId: string) {
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scan.getUserScans}/${scanId}${apiConfig.scan.summary}`)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getDashboardData(userId: string) {
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.user.userTeamDashboard}`)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }


    validateDomain(domainUrl: any) {
        let params = this.generateHttpParams(domainUrl);
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scan.validateDomain}`, {params})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getTeamUsers() {
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.teamUsers.getTeamUsers}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    addUserTeamMember(payload: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.teamUsers.addTeamUser}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    updateTeamUser(payload: any) {
        return this.httpClient.put(`${this.API_END_POINT}${apiConfig.teamUsers.updateTeamUser}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    deleteTeamUser(id: any) {
        return this.httpClient.delete(`${this.API_END_POINT}${apiConfig.teamUsers.deleteTeamUser}/${id}`)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );

    }

    getUserControls() {
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.user.getUserControls}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    isShowItem(number: number) {
        let c = this.storage.get('controls');
        let controls = String(c).replace('[', '').replace(']', '')
            .split(',');
        return controls.filter(cc => +cc == number)?.length;
    }

    reinitiateScan(scanId: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.scan.getUserScans}/${scanId}${apiConfig.scan.reinitiate}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    logoutUser() {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.user.logout}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    buySubscription(subscriptionId: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.subscription.subscriptions}/${subscriptionId}${apiConfig.subscription.buy}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    validatePayment(payload: any) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.subscription.validatePayment}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getSubscriptions() {
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.subscription.subscriptions}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getUserSubscriptions(userId) {
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.subscription.userSubscriptions}/${userId}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getComplianceHealthCheck() {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.compliance.scan}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    cloudLaunchScan(payload) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.cloudSecurity.launchScan}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getCloudUserScan(search) {
      let params = this.generateHttpParams(search);
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.cloudSecurity.getUserTeam}`, {params})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getCloudReportScan(scan_id) {
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.cloudSecurity.getUserScans}/${scan_id}${apiConfig.cloudSecurity.cloudSecurity}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    webLaunchScan(payload) {
        return this.httpClient.post(`${this.API_END_POINT}${apiConfig.webSecurity.launchWebScan}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getWebUserScan(search) {
        let params = this.generateHttpParams(search);
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.webSecurity.getUserTeam}`, {params})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getWebReportScan(scan_id) {
        return this.httpClient.get(`${this.API_END_POINT}${apiConfig.webSecurity.getUserScans}/${scan_id}${apiConfig.webSecurity.webSecurity}`, {})
            .pipe(
                map((res: any) => res),
                catchError((err: any) => {
                    return throwError(err.error);
                })
            );
    }

    getScanUsageLimit(){
      return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scanLimit.user}`, {})
        .pipe(
          map((res: any) => res),
          catchError((err: any) => {
            return throwError(err.error);
          })
        );
    }

    getScanOptionData(scan_id){
      return this.httpClient.get(`${this.API_END_POINT}${apiConfig.scanOption.scan}/${scan_id}${apiConfig.scanOption.option}`,{})
      .pipe(
      map((res:any) => res),
      catchError((err: any) => {
            return throwError(err.error);
          })
        );
    }

  getDashboardComparativeCount(){
    return this.httpClient.get(`${this.API_END_POINT}${apiConfig.comparative.scans}`,{})
      .pipe(
        map((res:any) => res),
        catchError((err: any) => {
          return throwError(err.error);
        })
      );
  }

  getUserPushNotifications() {
    return this.httpClient.get(`${this.API_END_POINT}${apiConfig.banner.userPushNotifications}`,{})
      .pipe(
        map((res:any) => res),
        catchError((err: any) => {
          return throwError(err.error);
        })
      );
  }



}
