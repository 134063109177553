import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {UiModule} from '../shared/ui/ui.module';
import {WidgetModule} from '../shared/widget/widget.module';

import {PagesRoutingModule} from './pages-routing.module';

import {NgbNavModule, NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {NgApexchartsModule} from 'ng-apexcharts';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {FullCalendarModule} from '@fullcalendar/angular';
import {DndModule} from 'ngx-drag-drop';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';

import {DashboardComponent} from './dashboard/dashboard.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BannerComponent } from './banner/banner.component';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 0.3
};

@NgModule({
    declarations: [DashboardComponent, BannerComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesRoutingModule,
        UiModule,
        Ng2SearchPipeModule,
        NgbNavModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgApexchartsModule,
        PerfectScrollbarModule,
        DndModule,
        FullCalendarModule,
        LeafletModule,
        WidgetModule,
      MatTooltipModule,
      TooltipModule.forRoot(),

    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class PagesModule {
}
