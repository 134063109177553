<div id="layout-wrapper">
    <ngx-spinner size="small"
      template="<img src='./assets/gifs/Logo-Loading.gif' />">
    </ngx-spinner>
   <div>
    <app-topbar class="scroll" (mobileMenuButtonClicked)="onToggleMobileMenu()" (settingsButtonClicked)="onSettingsButtonClicked()">
    </app-topbar>

    <app-sidebar></app-sidebar>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">
        <div class="page-content">
            <router-outlet>
                <app-alert></app-alert>
            </router-outlet>
        </div>

        <!--    <app-footer></app-footer>-->
    </div>

    <app-rightsidebar></app-rightsidebar>
</div>
<!-- END layout-wrapper -->
</div>
