<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box d-none d-sm-block">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-dark.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="20">
          </span>
        </a>
        <a href="/" class="logo logo-light">
          <span class="logo-sm ml-n2">
            <img src="assets/images/getSecured-Logo.png" alt="" height="35">
          </span>
          <span class="logo-lg">
            <img src="assets/images/getSecured-name.png" alt="" height="35">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-4 ml-n1 font-size-24 header-item waves-effect fixed"
              id="vertical-menu-btn"
              (click)="toggleMobileMenu($event)">
        <i class="menu-icon align-middle"></i>
      </button>

      <!-- App Search-->
      <!--      <form class="app-search d-none d-lg-block">-->
      <!--        <div class="position-relative">-->
      <!--          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">-->
      <!--          <span class="ri-search-line"></span>-->
      <!--        </div>-->
      <!--      </form>-->

    </div>

    <div class="d-flex">

      <!--            <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>-->
      <!--                <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"-->
      <!--                        id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true"-->
      <!--                        aria-expanded="false">-->
      <!--                    <i class="ri-search-line"></i>-->
      <!--                </button>-->
      <!--                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"-->
      <!--                     aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>-->

      <!--                    <form class="p-3">-->
      <!--                        <div class="form-group m-0">-->
      <!--                            <div class="input-group">-->
      <!--                                <input type="text" class="form-control" placeholder="Search ...">-->
      <!--                                <div class="input-group-append">-->
      <!--                                    <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i>-->
      <!--                                    </button>-->
      <!--                                </div>-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                    </form>-->
      <!--                </div>-->
      <!--            </div>-->

      <!--      <div class="dropdown d-inline-block" ngbDropdown>-->
      <!--        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>-->
      <!--          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span-->
      <!--            class="ml-1">{{countryName}}</span>-->
      <!--          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">-->
      <!--          <span *ngIf="flagvalue === undefined" class="ml-1">English</span>-->
      <!--        </button>-->
      <!--        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>-->
      <!--          &lt;!&ndash; item&ndash;&gt;-->
      <!--          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"-->
      <!--            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">-->
      <!--            <img src="{{item.flag}}" alt="user-image" class="mr-1" height="12"> <span-->
      <!--              class="align-middle">{{item.text}}</span>-->
      <!--          </a>-->
      <!--          &lt;!&ndash; item&ndash;&gt;-->
      <!--        </div>-->
      <!--      </div>-->


      <!--<div class="dropdown d-lg-inline-block ml-1 mr-3">-->
      <!--<button type="button" class="btn btn-blue-secondary noti-icon waves-effect" (click)="onNewScanClick()">-->
      <!--<span class="btn-text">New Scan <b class="plus-icon"></b></span>-->
      <!--</button>-->
      <!--</div>-->


      <!--<div class="d-flex align-items-center"><span class="pr-2">Scan Credit:</span>-->
      <!--<button class="size" > 10</button></div>-->
      <div class="dropdown d-none d-lg-inline-block ml-1 ">
        <button type="button" class="btn header-item noti-icon waves-effect d-flex align-items-center" (click)="fullscreen()">
          <i class="ri-fullscreen-line"></i>
        </button>
      </div>


      <!--      <div class="dropdown d-inline-block" ngbDropdown>-->
      <!--        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"-->
      <!--          id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
      <!--          <i class="ri-notification-3-line"></i>-->
      <!--          <span class="noti-dot"></span>-->
      <!--        </button>-->
      <!--        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu-->
      <!--          aria-labelledby="page-header-notifications-dropdown">-->
      <!--          <div class="p-3">-->
      <!--            <div class="row align-items-center">-->
      <!--              <div class="col">-->
      <!--                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>-->
      <!--              </div>-->
      <!--              <div class="col-auto">-->
      <!--                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">-->
      <!--            <a href="" class="text-reset notification-item">-->
      <!--              <div class="media">-->
      <!--                <div class="avatar-xs mr-3">-->
      <!--                  <span class="avatar-title bg-primary rounded-circle font-size-16">-->
      <!--                    <i class="ri-shopping-cart-line"></i>-->
      <!--                  </span>-->
      <!--                </div>-->
      <!--                <div class="media-body">-->
      <!--                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>-->
      <!--                  <div class="font-size-12 text-muted">-->
      <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>-->
      <!--                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>-->
      <!--                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
      <!--            <a href="" class="text-reset notification-item">-->
      <!--              <div class="media">-->
      <!--                <img src="assets/images/users/avatar-3.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">-->
      <!--                <div class="media-body">-->
      <!--                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>-->
      <!--                  <div class="font-size-12 text-muted">-->
      <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>-->
      <!--                    <p class="mb-0"><i-->
      <!--                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' | translate}}</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
      <!--            <a href="" class="text-reset notification-item">-->
      <!--              <div class="media">-->
      <!--                <div class="avatar-xs mr-3">-->
      <!--                  <span class="avatar-title bg-success rounded-circle font-size-16">-->
      <!--                    <i class="ri-checkbox-circle-line"></i>-->
      <!--                  </span>-->
      <!--                </div>-->
      <!--                <div class="media-body">-->
      <!--                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>-->
      <!--                  <div class="font-size-12 text-muted">-->
      <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>-->
      <!--                    <p class="mb-0"><i-->
      <!--                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' | translate}}</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
      <!--            <a href="" class="text-reset notification-item">-->
      <!--              <div class="media">-->
      <!--                <img src="assets/images/users/avatar-4.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">-->
      <!--                <div class="media-body">-->
      <!--                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>-->
      <!--                  <div class="font-size-12 text-muted">-->
      <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>-->
      <!--                    <p class="mb-0"><i-->
      <!--                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' | translate}}</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
      <!--          </perfect-scrollbar>-->
      <!--          <div class="p-2 border-top">-->
      <!--            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">-->
      <!--              <i class="mdi mdi-arrow-right-circle mr-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}-->
      <!--            </a>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->



      <div class="dropdown d-inline-block user-dropdown" ngbDropdown (click)="getscanLimit()">
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect mr-3"
                id="page-header-user-dropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class=" row d-flex justify-content-between pr-0">
            <div class="d-flex justify-content-end align-items-center">
              <!--<span class=" d-xl-inline-block ml-1 text-center"><b class="text-dark">John smith</b>-->
              <span class=" d-xl-inline-block ml-1 text-center"><b class="text-dark">{{user ? user.firstName + ' ' + user.lastName : ''}}</b>

                <!--<div class="p-0 m-0 mt-n2 text-right"><small>(Admin)</small></div></span>-->
                <!--<div class="p-0 m-0 mt-n1 text-left"><small>({{user && user.teamUserRole ? (user.teamUserRole.replace('team_', '') | titlecase) : ''}})</small>-->
                <!--</div>-->
                <div class="p-0 m-0 mt-n1 text-left box"><small>{{data.subscription_title}}</small></div>
                <!--<div class="p-0 m-0 mt-n1 text-left"><small>your plan</small></div>-->
                            </span>
            </div>
            <div class=" ml-3 d-flex align-items-center">
              <!--<img class="rounded-circle header-profile-user" src="assets/images/users/avatar-2.jpg"-->
              <!--alt="Header Avatar">-->
              <i class=" d-flex align-items-center mt-1 pt-1 drop-down-icon d-inline-block"></i>
            </div>
          </div>

        </button>

        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu >
          <!-- item-->
          <!--                  <a class="dropdown-item" routerLink="/members"><i class="ri-user-line align-middle mr-1"></i>-->
          <!--                    Members</a>-->
          <!--<a class="dropdown-item" href="javascript: void(0);"><i class="ri-user-line align-middle mr-1"></i>-->
          <!--{{ 'HEADER.LOGIN.PROFILE' | translate}}</a>-->
          <!--<a class="dropdown-item" href="javascript: void(0);"><i-->
          <!--class="ri-wallet-2-line align-middle mr-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a>-->
          <!--<a class="dropdown-item d-block" href="javascript: void(0);"><span-->
          <!--class="badge badge-success float-right mt-1">11</span><i-->
          <!--class="ri-settings-2-line align-middle mr-1"></i>{{ 'HEADER.LOGIN.SETTINGS' | translate}}-->
          <!--</a>-->
          <!--<a class="dropdown-item" href="javascript: void(0);"><i-->
          <!--class="ri-lock-unlock-line align-middle mr-1"></i>-->
          <!--{{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a>-->
          <!--<div class="dropdown-divider"></div>-->

          <div  class="dropdown-item pb-3" ngbDropdownItem >
          <!--<div >Total Scan Credit</div>-->
            <div class="progress-bar pt-3 ">
              <div class="d-flex justify-content-between pb-1">
                <span >Credits(Used/Total) </span>
                <span *ngIf="data.total_credit >= 999">{{data.used_credit}}/Unlimited</span>
                <span *ngIf="data.total_credit <= 999">{{data.used_credit}}/{{data.total_credit}}</span>
              </div>
              <ngb-progressbar  height="4px" width="70px;" type="success" [value]="(data.used_credit/data.total_credit)*100"></ngb-progressbar>
            </div>
          </div>
          <a class="dropdown-item text-success d-flex align-items-center" id="documentation" ngbDropdownItem href="http://docs.getsecured.ai/" target=_blank;>
            <i class="ri-booklet-line align-middle mr-2 text-sucess"></i>DOCUMENTATION</a>

          <a class="dropdown-item text-danger" ngbDropdownItem href="javascript: void(0);" (click)="logout()"><i
            class="ri-shut-down-line align-middle mr-1 text-danger" style="border-top:1px;"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <!--      <div class="dropdown d-inline-block">-->
      <!--        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"-->
      <!--          (click)="toggleRightSidebar()">-->
      <!--          <i class="ri-settings-2-line"></i>-->
      <!--        </button>-->
      <!--      </div>-->

    </div>
  </div>
</header>
