import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as Feather from 'feather-icons';

@Component({
    selector: 'app-dashboard-card',
    templateUrl: './dashboard-card.component.html',
    styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit, AfterViewInit {

    @Input()
    public count: number;
    @Input()
    public title: string;

    @Input()
    public icon: string;

    @Input()
    public bgClass: string;
    public hovered: any = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        Feather.replace();
    }

}
