import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {AlertService} from "../../shared/alert/services/alert.service";
import {UserService} from "../services/user.service";
import {NgxSpinnerService} from "ngx-spinner";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertService, private router: Router, private userService: UserService,
                private spinner: NgxSpinnerService,private dialogRef: MatDialog) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            setTimeout(() => this.spinner.hide(), 100);
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                sessionStorage.clear();
                localStorage.clear();
                this.dialogRef.closeAll();
                // window.location.href = '/account/login';
                this.alertService.error('Session Expired. Please login again');
                this.userService.logoutUser();
                setTimeout(() => this.router.navigate(['/login']), 1000);
            } else if (err.status === 403) {
                // auto logout if 401 response returned from api
                sessionStorage.clear();
                localStorage.clear();
                this.alertService.error('Unauthorized');
                this.userService.logoutUser();
                setTimeout(() => this.router.navigate(['/login']), 1000);
            } else if (err.status === 404 || err.status === 502) {
                this.alertService.error('Server is down');
                return throwError(err);
                // setTimeout(() => this.router.navigate(['/login']), 1000);
            } else {

                // console.error(err);
                const error = err.error.message ? err.error.message : err.error.message = 'Something went wrong. please try again later';

                // console.log('*********');
                console.error(error);
                return throwError(err);
            }
        }));
    }
}
