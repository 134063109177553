<div id="layout-wrapper">
    <app-horizontaltopbar></app-horizontaltopbar>
    <app-horizontalnavbar></app-horizontalnavbar>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">
        <div class="page-content">
            <router-outlet>
                <app-alert></app-alert>
            </router-outlet>
        </div>
        <app-footer></app-footer>

    </div>

    <app-rightsidebar></app-rightsidebar>
</div>
<!-- END layout-wrapper -->
