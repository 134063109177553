import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LOCAL_STORAGE, SESSION_STORAGE, StorageService, WebStorageService} from 'ngx-webstorage-service';
import {AlertService} from "../../shared/alert/services/alert.service";


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, @Inject(LOCAL_STORAGE) private storage: WebStorageService,
                private alertService: AlertService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.storage.get('loggedInUser')) {
            this.alertService.error('UnAuthorized Please login before accessing the route');
            sessionStorage.clear();
            localStorage.clear();
            this.router.navigate(['/account/login'], {queryParams: {returnUrl: state.url}});
            return false;
        }
        return true;

    }

}
